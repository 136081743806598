import { SVGProps } from "react";

export const Cenitt = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2049 2049" {...props}>
    <title>{"cenitt"}</title>
    <path
      d="m1380.13 823.11-246.54-427c-48.49-84-169.69-84-218.18 0l-246.54 427c-37.07 64.21 9.27 144.47 83.41 144.47h544.44c74.14 0 120.48-80.26 83.41-144.47Z"
      style={{
        fill: "white",
      }}
    />
    <path
      d="m495.43 1123.5-294.5 510.1c-48.48 84 12.12 188.94 109.09 188.94H1739c97 0 157.57-105 109.09-188.94l-294.5-510.1a126 126 0 0 0-109.09-63h-840a126 126 0 0 0-109.07 63Z"
      style={{
        fill: "white",
      }}
    />
  </svg>
);
