import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import styles from "./index.module.css";
import { Cenitt } from "./Cenitt";

const InteractiveBubble = () => {
  const interBubbleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interBubble = interBubbleRef.current;
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    if (interBubble) {
      const move = () => {
        curX += (tgX - curX) / 20;
        curY += (tgY - curY) / 20;
        interBubble.style.transform = `translate(${Math.round(
          curX
        )}px, ${Math.round(curY)}px)`;
        requestAnimationFrame(move);
      };

      const handleMouseMove = (event: MouseEvent) => {
        tgX = event.clientX - interBubble.offsetWidth / 2;
        tgY = event.clientY - interBubble.offsetHeight / 2;
      };

      window.addEventListener("mousemove", handleMouseMove);
      move();

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);

  return (
    <div className={styles["gradient-bg"]}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
      <div className={styles["gradients-container"]}>
        <div className={styles["gradients-container"]}>
          <div className={styles.g1}></div>
          <div className={styles.g2}></div>
          <div className={styles.g3}></div>
          <div className={styles.g4}></div>
          <div className={styles.g5}></div>
          <div className={styles.interactive} ref={interBubbleRef}></div>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <React.StrictMode>
      <div className={styles["container"]}>
        <div className={styles["logo-container"]}>
          <Cenitt className={styles.logo} />
          <div>Cenitt</div>
        </div>
      </div>
      <InteractiveBubble />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
